.slide-item {
    position: relative;
    width: 100%;
}
.slide-content {
    position: absolute;
    color: white;
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 615px;
    left: 0;
}
.hero-wrapper.hero-wrapper2.NoBg {
    background-image: none;
    max-height: 640px;
    height: auto;
}
.hero-wrapper.hero-wrapper2.NoBg .slide-item img {
    max-width: 100%;
    min-width: 100%;
    min-height: 100%;
}
.slide-item:after {
    content: "";
    background-color: rgba(0,0,0,.5);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.hero-wrapper.hero-wrapper2.NoBg .slick-slider {
    max-height: 640px;

}
.hero-wrapper .slick-dots {
    bottom: 10px;
}
.hero-wrapper .slick-dots li button {
    width: 15px;
    height: 15px;
    background: #999;
    border-radius: 100%;
}
.hero-wrapper .slick-dots li button:before{
    display: none;
}
.hero-wrapper .slick-dots li.slick-active button{
    background-color: #fff;
}
.hero-wrapper .slick-slider button.slick-arrow {
    display: none !important;
}
.sliderContent h1 {
    margin: 0 0 10px 0;
    font-weight: 600;
    letter-spacing: normal;
}
.sliderContent p{
    margin: 0 0 10px 0;
    letter-spacing: normal;
}
.sliderContent a.button.theme-btn {
    background: #f11200;
    border-color: #f11200;
}
/* .sliderContent h4.sec__title.The.Katika.Foundation {
    font-size: 16px;
    font-weight: 600;
} */



.cities .latest-location-tab {
    display: flex;
    justify-content: center;
    background: transparent !important;
    align-items: center;
    padding-bottom: 20px;
  }
  .cities .latest-location-tab .latest-in {
    color: #000;
    margin: 0;
    /* border-bottom: 2px solid transparent; */
    font-size: 16px;
}
  
  .cities .latest-location-tab .location .BottomBorder {
    padding-bottom: 0;
    border-bottom: 4px solid;
  }
  main.home-1 .react-tabs__tab-list h2.location span.MidArrow svg {
    color: #000;
    font-size: 16px;
}
main.home-1 .react-tabs__tab-list h2.location span.MidArrow {
    margin: 0 10px;
}
.login.section .login.NewDesign {
    border: 0;
    width: auto;
    margin: 0 10px;
}
.login.section .login.NewDesign .dropdrown-list.dropdown {
    padding: 0 5px;
}
.login.section .login.NewDesign .dropdrown-list.dropdown .dropdown-menu.show {
    transform: translate(-10px, 35px) !important;
    width: 230px;
    padding: 0px;
}
.login.section .login.NewDesign .dropdrown-list.dropdown button {
    border: 0;
}
.login.section .login.NewDesign .dropdrown-list.dropdown .dropdown-menu.show hr {
    border-top: 0;
    width: 100%;
    margin: 0;
}
.login.section .login.NewDesign a {
    padding: 0 5px;
}
span.FindFor {
    font-weight: 600;
    font-size: 18px;
    color: #555;
}
.cities .latest-location-tab .dropdown button.btn.btn-primary {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-top: -1px;
  }
  .cities .latest-location-tab .show.dropdown #dropdown-basic1 {
    box-shadow: none;
  }
  .top-right.user-login-info.MarginTop .login.section .dropdrown-list a {
    align-items: center;
    display: flex;
    font-size: 13px;
}
.login.section .login.NewDesign .dropdrown-list.dropdown a svg {
    font-size: 20px;
    margin: 0 5px 0 0px;
    text-align: center;
}
.top-right.user-login-info .login.section .dropdrown-list a.MenuDropDown img {
    width: 20px;
    margin: 0 8px 0 0px;
}
main.home-1 .react-tabs__tab-list h2.location {
    margin-bottom: 0;
}
main.home-1 .cities .list button.dropdown-toggle.btn.btn-primary:after {
    border-top-color: #f11200;
    position: absolute;
    left: 0;
    top: 9px;
    display: none;
}
main.home-1 .slick-dots li{
    margin: 0;
}
main.home-1 .main-menu-content.listin_nav ul.home-top-nav a {
    padding-top: 5px;
    padding-bottom: 5px;
}
/* .react-autosuggest__container {
    width: 100%;
} */
.Ads a {
    display: inline-block;
}
.logo.listing a {
    display: inline-block;
}
/* .cities .list .react-tabs {
    position: relative;
    width: 350px;
    margin: 0 auto;
} */
.list.CityListings #react-autowhatever-1 {
    border: 0;
    width: 260px;
    right: 0;
    margin: auto;
    text-align: left;
    top: 45px;
    display: inline-block;
    box-shadow: 0 0 6px #ccc;
}
.list.CityListings .react-autosuggest__container input.form-control.c1.near-feild {
    border: 0;
    padding-top: 0;
    font-weight: 700;
    color: #f11200;
    font-size: 16px;
    padding-bottom: 0;
    border-bottom: 2px solid #f11200;
    border-radius: 0;
    width: auto;
    display: inline-block;
    padding: 0;
    max-width: 200px;
}
.list.CityListings .react-autosuggest__container input.form-control.c1.near-feild:focus {
    outline: 0;
    box-shadow: none;
}
.list.CityListings .react-autosuggest__container{
    position: relative;
}
.contact-form-action .react-autosuggest__container {
    width: 100%;
}
span.MidArrow {
    margin: 0 10px;
}
span.MidArrow svg {
    width: 70%;
}
.list.CityListings ul.react-autosuggest__suggestions-list li {
    color: #000;
    padding-left: 15px;
}
.list.CityListings .react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open {
    height: auto;
    max-height: 400px;
}











input {
    font-size: 1.3em;
    padding: 5px;
    margin-bottom: 1em;
  }
  
  input.type2 {
    letter-spacing: 4px;
    font-style: italic;
  }